import * as React from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { Map } from '../map/Map';
import { useMountEffect } from '../../hooks/useMountEffect';
import { useLayoutContext } from '../../contexts/LayoutContext';
import { MapSidebar } from '../map/MapSidebar';
import { MobileModal } from '../content/MobileMapModal';
import { SEO } from '../seo';
import { graphql, PageProps } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useLocaleContext } from '../../contexts/LocaleContext';

const Main = styled.main`
	width: 100%;
	height: 100%;

	&& {
		display: flex;
		flex-flow: row;
	}
`;

const MapWrapper = styled(MobileModal)`
	background-color: white;
`;

export default function MapTemplate({ pageContext }: PageProps<null, { translations: any }>) {
	const { setBreadcrumbs, setFooterHidden } = useLayoutContext();
	const { setPageTranslations } = useLocaleContext();
	const { t } = useTranslation();
	useMountEffect(() => setBreadcrumbs([]));
	const id = 'S00-linnaosade-areng';

	useEffect(() => {
		setPageTranslations(pageContext.translations);
	}, []);

	useEffect(() => {
		setFooterHidden(true);
		return () => {
			setFooterHidden(false);
		};
	}, []);

	const sidebar = <MapSidebar />;

	return (
		<MapWrapper>
			<Main>
				<SEO title={t('Linnaosade tuleviku kaart')} />
				<Map id={id} enableFullscreen={false} sidebar={sidebar} />
			</Main>
		</MapWrapper>
	);
}

export const pageQuery = graphql`
	query MapPage($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
