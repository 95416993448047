import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { userShortcodes } from '../../lib/userShortcodes';
import CloseIcon from '../../images/x_kinni.svg';
import { screenSize } from '../../styles/ScreenSize';
import { useMapContext } from './MapContext';
import { AllLinnaosadSidebarContentQuery } from '../../generated/graphql-types';
import { useLocaleContext } from '../../contexts/LocaleContext';

const SidebarWrapper = styled.div`
	width: 400px;
	padding: 26px 16px;
	font-size: 16px;
	box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.1);
	overflow-y: auto;

	strong {
		color: #0072ce;
		font-weight: normal;
	}

	h2 {
		color: #0072ce;
		font-weight: bold;
		font-size: 20px;
		line-height: 20px;
	}

	${screenSize.sm} {
		width: 100%;
		height: 100%;

		position: fixed;
		background-color: white;
		z-index: 10;
	}
`;

const ContentWrapper = styled.div`
	padding-right: 42px;

	& > :last-child {
		margin-bottom: 16px;
	}
`;

const CloseButton = styled.button`
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
	position: fixed;
	right: 16px;
	top: 64px;
	padding-bottom: 4px;
	padding-top: 8px;

	svg {
		width: 26px;
		height: 26px;
	}

	&:focus {
		background-color: var(--grey);
		border-radius: 5px;
	}
`;

export function MapSidebar() {
	const { sidebarItem, setSidebarItem } = useMapContext();
	const { locale } = useLocaleContext();

	const {
		allMdx: { nodes: linnaosad },
	} = useStaticQuery<AllLinnaosadSidebarContentQuery>(graphql`
		query AllLinnaosadSidebarContent {
			allMdx(filter: { frontmatter: { group: { eq: "linnaosad" } } }) {
				nodes {
					fields {
						locale
					}
					body
					frontmatter {
						slug
					}
				}
			}
		}
	`);

	if (!sidebarItem) return null;
	const item = linnaosad.find(
		(linnaosa) => linnaosa?.frontmatter?.slug === sidebarItem && linnaosa?.fields?.locale === locale
	);
	if (!item) return null;

	return (
		<SidebarWrapper>
			<CloseButton onClick={() => setSidebarItem(null)}>
				<CloseIcon aria-hidden="true" />
			</CloseButton>
			<ContentWrapper>
				<MDXProvider components={userShortcodes}>
					<MDXRenderer>{item.body}</MDXRenderer>
				</MDXProvider>
			</ContentWrapper>
		</SidebarWrapper>
	);
}
